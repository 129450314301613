// extracted by mini-css-extract-plugin
export var marginBottomLg = "HorizontalSpacing-module--margin-bottom-lg---fYl0";
export var marginBottomMd = "HorizontalSpacing-module--margin-bottom-md--omBc-";
export var marginBottomSm = "HorizontalSpacing-module--margin-bottom-sm--glqt4";
export var marginBottomXl = "HorizontalSpacing-module--margin-bottom-xl--0bLvH";
export var marginBottomXs = "HorizontalSpacing-module--margin-bottom-xs--4+GtB";
export var marginBottomXxl = "HorizontalSpacing-module--margin-bottom-xxl--x+kiD";
export var marginTopLg = "HorizontalSpacing-module--margin-top-lg--vp52p";
export var marginTopMd = "HorizontalSpacing-module--margin-top-md--AgWBY";
export var marginTopSm = "HorizontalSpacing-module--margin-top-sm--WHR5U";
export var marginTopXl = "HorizontalSpacing-module--margin-top-xl--R7B8v";
export var marginTopXs = "HorizontalSpacing-module--margin-top-xs--BrJx6";
export var marginTopXxl = "HorizontalSpacing-module--margin-top-xxl--db5fO";
export var paddingBottomLg = "HorizontalSpacing-module--padding-bottom-lg--1DKs+";
export var paddingBottomMd = "HorizontalSpacing-module--padding-bottom-md--hhlpz";
export var paddingBottomSm = "HorizontalSpacing-module--padding-bottom-sm--rZ9Q9";
export var paddingBottomXl = "HorizontalSpacing-module--padding-bottom-xl--UW9U5";
export var paddingBottomXs = "HorizontalSpacing-module--padding-bottom-xs--QX4Ap";
export var paddingBottomXxl = "HorizontalSpacing-module--padding-bottom-xxl--Ue8Xj";
export var paddingTopLg = "HorizontalSpacing-module--padding-top-lg--jf0zO";
export var paddingTopMd = "HorizontalSpacing-module--padding-top-md--HGwIq";
export var paddingTopSm = "HorizontalSpacing-module--padding-top-sm--5Q3Lf";
export var paddingTopXl = "HorizontalSpacing-module--padding-top-xl--Ghby7";
export var paddingTopXs = "HorizontalSpacing-module--padding-top-xs--sJWkz";
export var paddingTopXxl = "HorizontalSpacing-module--padding-top-xxl--3xwSo";