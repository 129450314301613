// extracted by mini-css-extract-plugin
export var paddingLeftLg = "VerticalSpacing-module--padding-left-lg--ATHUf";
export var paddingLeftMd = "VerticalSpacing-module--padding-left-md--rsUAz";
export var paddingLeftSm = "VerticalSpacing-module--padding-left-sm--fVS3l";
export var paddingLeftXl = "VerticalSpacing-module--padding-left-xl--OoD+6";
export var paddingLeftXs = "VerticalSpacing-module--padding-left-xs--VGybS";
export var paddingLeftXxl = "VerticalSpacing-module--padding-left-xxl--eNSK5";
export var paddingRightLg = "VerticalSpacing-module--padding-right-lg--MWeHc";
export var paddingRightMd = "VerticalSpacing-module--padding-right-md--F0FtX";
export var paddingRightSm = "VerticalSpacing-module--padding-right-sm--PkzgC";
export var paddingRightXl = "VerticalSpacing-module--padding-right-xl--0k6iO";
export var paddingRightXs = "VerticalSpacing-module--padding-right-xs--DOe6L";
export var paddingRightXxl = "VerticalSpacing-module--padding-right-xxl--Wzicz";